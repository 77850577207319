<template>
<div id="faq-container" class="h-100 ">

  <div class="container text-left  d-flex flex-column   h-100
  overflow-hidden p-0 
   "
  >
    <div class="image-area d-sm-none"/>
    <div class=" bg-white  ml-auto 
        flex-grow-1 overflow-auto my-sm-4 
    "
    id="faq-place" v-fade-in="{delay:150}"
    >

      <!-- <h3 class="logo-font text-blue mb-4">
          FAQs
      </h3> -->

      <div class="p-4  ">

        <div v-for="q in questions" :key="q.q" >
            <h5 class="logo-font   ">
              {{q.q}}
            </h5>
            <p v-html="q.a" >
              
            </p>
        </div>

      </div>
          

    </div>
    <Footer class=" darkblue" :inverted="true" />

  </div>
</div>

</template>

<script type='text/javascript'> (function(){ var s1=document.createElement('script'),s0=document.getElementsByTagName('script')[0]; s1.async=true; s1.src='https://us.floatbot.ai/portal/chatbot/?botId=604cc067429f9b56f83efa75'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); })(); </script>

<script>
import Footer from '../components/special/Footer'
import texts from '../texts'
export default {
  components:{
    Footer
  },
  data(){
    return{
      questions:texts.faq.questions
    }
  }
}
</script>

<style scoped>

#faq-container h5{
  color: var(--darkblue);
}
#faq-container p{
  color: var(--normalgray);
}
 
 @media screen and (min-width:576px){
   #faq-place{
     box-shadow: 0 0 8px rgba(0, 0, 0, 0.534);
     
   }
 }

#faq-place{
  max-width: 640px;
}
@media screen and (orientation: portrait) {
 

}
 
.image-area{
  max-height: 780px;
  min-height: 200px;

  background-image: var(--faq-image);
  background-size: cover;;
}

</style>